export default function RemoteActions() {
  return (
    <div className="m-2 border border-1 border-gray p-4 rounded">
      <div>
        <p className={"h3"}>Actions</p>
        <div className="d-flex w-50 gap-4 ">
          <select
            aria-controls="dtBasicExample"
            className="custom-select-form text-secondary form-control form-control-sm"
          >
            <option className="text-secondary" defaultValue="true">
              Select Action
            </option>
            <option value={"ActionName"}>ActionName</option>
          </select>
          <button className="btn btn-primary px-4">Excute</button>
        </div>
      </div>
      <div className={"h3 py-4 "}>Results</div>
    </div>
  );
}
