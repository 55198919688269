import { useEffect } from "react";
import ReactApexChart from "react-apexcharts";
export const LineChart = (props: {
  max?: number | undefined;
  data: any;
  title: string;
  yAxisTitle: string | undefined;
  xAxisTitle: string | undefined;
  yAxisTickAmount: number;
  fixed?: number;
}) => {
  const { max, data, title, yAxisTitle, xAxisTitle, yAxisTickAmount, fixed } =
    props;
  useEffect(() => {}, [data]);
  const xAxisData: Array<number> = [];
  const yAxisData: Array<number> = [];
  data.data.map((el: any) => {
    xAxisData.push(el.xValue);
    yAxisData.push(el.yValue);
    return 0;
  });
  return (
    <ReactApexChart
      series={[
        {
          name: title,
          data: yAxisData || [],
        },
        {
          name: "average Value",
          data: data.avg || [],
          color: "#00FF00",
        },
      ]}
      options={{
        chart: {
          width: "100%",
          toolbar: {
            show: false,
          },
          zoom: {
            enabled: false,
            autoScaleYaxis: false,
          },
          type: "line",
        },
        title: {
          text: title,
          align: "left",
          margin: 10,
          offsetX: 0,
          offsetY: 0,
          floating: false,
          style: {
            fontSize: "20px",
            fontWeight: "500",
            fontFamily: undefined,
            color: "#121212",
          },
        },
        tooltip: {
          theme: "dark",
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          curve: "smooth",
          width: 2,
        },
        xaxis: {
          categories: xAxisData,
          labels: {
            hideOverlappingLabels: true,
            style: {
              colors: "#000",
              fontSize: "12px",
            },
          },
          title: {
            text: xAxisTitle,
            offsetX: 0,
            offsetY: 0,
            style: {
              color: undefined,
              fontSize: "12px",
              fontFamily: "Helvetica, Arial, sans-serif",
              fontWeight: 600,
              cssClass: "apexcharts-xaxis-title",
            },
          },
        },
        yaxis: {
          forceNiceScale: true,
          tickAmount: yAxisTickAmount,
          min: 0,
          max: max,
          title: {
            text: yAxisTitle,
            offsetX: 0,
            offsetY: 0,
            style: {
              color: undefined,
              fontSize: "12px",
              fontFamily: "Helvetica, Arial, sans-serif",
              fontWeight: 600,
              cssClass: "apexcharts-xaxis-title",
            },
          },
          labels: {
            formatter: (value: any) => {
              return value?.toFixed(fixed === undefined ? 2 : fixed);
            },
            style: {
              colors: "#000",
              fontSize: "12px",
            },
          },
        },
        legend: {
          show: false,
        },
        grid: {
          strokeDashArray: 5,
          yaxis: {
            lines: {
              show: true,
            },
          },
          xaxis: {
            lines: {
              show: true,
            },
          },
        },
        markers: {
          size: 2,
          colors: ["#4049b5", "#00FF00"],
          strokeColors: ["#4049b5"],
          strokeWidth: 0.1,
          strokeOpacity: 0.9,
          strokeDashArray: 0,
          fillOpacity: 1,
          discrete: [],
          shape: "circle",
          radius: 2,
          offsetX: 0,
          offsetY: 0,
          showNullDataPoints: true,
        },
        fill: {
          type: "solid",
          colors: ["#fff", "#fff", "#fff"],
        },
        colors: ["#4049b5"],
      }}
      height={300}
      width={"100%"}
    />
    // <div className="col-xl-12">
    //   <div className="it-performance-chart-box it-performance-container">

    //   </div>
    // </div>
  );
};
