import { ReactElement, useCallback, useEffect, useState } from "react";
import { initialEndDate, initialStartDate, PageHeader } from "./PageHeader";
import { Loader } from "./Loader/Loader";
import { NoData } from "./NoData";
import { Filters, FiltersProvider } from "../context/FiltersContext";
import PageLinks from "./PageLinks";
import { ItemFilterContextContainer } from "../context/ItemFilterContext";
import { ConvertToUTC } from "../utils/Date-Time-Util/DateConverter";

export type PageProps<T> = {
  title: {
    mainTitle: string;
    subTitle: string;
  };
  fetchData: (filters: Filters) => Promise<T>;
  shouldShowNoData: (data: T) => boolean;
  body: (data: T, onDataChange: () => void) => ReactElement;
  items?: Array<Object> | undefined;
};
const pagesWithOutUsersFilter = [
  "Web Availability",
  "License Monitoring",
  "Time Tracker",
  "Domain Management",
  "Applications",
  "Device-Information",
  "User-Activity",
  "System-Resources",
  "Assign Role",
  "Configure Alerts",
  "Remote Actions",
];

const pagesWithOutDateFilter = [
  "Applications Compliance",
  "User-Activity",
  "License Monitoring",
  "Time Tracker",
  "Application Usage",
  "Applications Usage Report",
  "Domain Management",
  "Devices List",
  "Device-Information",
  "System-Resources",
  "Applications",
  "Assign Role",
  "Configure Alerts",
  "Remote Actions",
];
export function Page<T>(props: PageProps<T>) {
  const { title, fetchData, shouldShowNoData, body } = props;

  const [filters, setFilters] = useState<Filters>({
    startDate: initialStartDate,
    endDate: initialEndDate,
    group: 0,
    user: undefined,
  });
  const [loadingState, setLoadingState] = useState<string>("loading");
  //@ts-ignore
  const [data, setData] = useState<T>([]);

  const fetch = useCallback(async () => {
    try {
      filters.startDate = ConvertToUTC(filters.startDate);
      filters.endDate = ConvertToUTC(filters.endDate);

      const data = await fetchData(filters);
      setData(data);
      setLoadingState("loaded");
    } catch (error) {
      setLoadingState("errored");
    }
  }, [filters, fetchData]);

  useEffect(() => {
    setLoadingState("loading");
    fetch();
  }, [fetchData, filters, fetch]);
  const pageHeader = (
    <PageHeader
      disabled={loadingState === "loading"}
      title={title}
      onFilterChanged={(startDate, endDate, group, user) => {
        setFilters({ startDate, endDate, group, user });
      }}
      isDateFilter={!pagesWithOutDateFilter.includes(title.subTitle)}
      isUserFilter={!pagesWithOutUsersFilter.includes(title.subTitle)}
      isGroupFilter={!pagesWithOutUsersFilter.includes(title.subTitle)}
    />
  );

  return (
    <FiltersProvider filters={filters}>
      <ItemFilterContextContainer>
        <div className="page-container">
          {pageHeader}
          {props?.items?.length !== undefined && (
            <PageLinks items={props.items} />
          )}

          {loadingState === "loading" && <Loader />}

          {loadingState === "loaded" &&
            (shouldShowNoData(data) ? (
              <NoData />
            ) : (
              body(data, () => {
                fetch();
              })
            ))}

          {loadingState === "errored" && (
            <div className="mt-5">
              Something wrong happened. Please, contact the developers.
            </div>
          )}
        </div>
      </ItemFilterContextContainer>
    </FiltersProvider>
  );
}
