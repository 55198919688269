import { MutableRefObject, useRef, useEffect, useState } from "react";
import { Dropdown } from "react-bootstrap";
import { user } from "../../client/AuthedClient";
import { useAuthedClient } from "../../context/AuthedClientContext";
import "react-datepicker/dist/react-datepicker.css";
import { DateTime } from "luxon";
import { DateRangePicker } from "rsuite";
import "../../css/PageHeaderStyles.css";
// import { PageHeader } from "../PageHeader";

export const TimeUtiHeader = (props: {
  disabled: boolean;
  callTimeUtil: (id: number | undefined, date: Date, date2: Date) => void;
}) => {
  const [currentUser, setCurrentUser] = useState<number | undefined>(0);
  const userInputEl = useRef<HTMLInputElement | null>(null);
  // Dummy commit

  const { callTimeUtil, disabled } = props;
  const [dropdownSearchTerm, setDropDownSearchTerm] = useState("");

  const [users, setUsers] = useState<Array<user>>([]);

  const [currentUserName, setCurrentUserName] = useState("All Devices");
  const [userid, setUserId] = useState(0);
  const { afterToday }: any = DateRangePicker;
  const auth = useAuthedClient();
  const options: Array<{
    name: string;
    startDate: DateTime;
    endDate: DateTime;
  }> = [
    {
      name: "Today",
      startDate: DateTime.now(),
      endDate: DateTime.now(),
    },
    {
      name: "Yesterday",
      startDate: DateTime.now().minus({ day: 1 }),
      endDate: DateTime.now().minus({ day: 1 }),
    },
    {
      name: "Last 7 Days",
      startDate: DateTime.now().minus({ day: 6 }),
      endDate: DateTime.now(),
    },
    {
      name: "Last 30 Days",
      startDate: DateTime.now().minus({ day: 29 }),
      endDate: DateTime.now(),
    },
    {
      name: "This month",
      startDate: DateTime.now().set({ day: 1 }),
      endDate: DateTime.now(),
    },
    {
      name: "Last month",
      startDate: DateTime.now().minus({ month: 1 }).set({ day: 1 }),
      endDate: DateTime.now().set({ day: 1 }).minus({ day: 1 }),
    },
  ];

  const dateRangePickerRanges = options.map((option) => ({
    label: option.name,
    value: [option.startDate.toJSDate(), option.endDate.toJSDate()],
    closeOverlay: true,
  }));
  const [startEndDate, setStartEndDate] = useState<[Date, Date]>([
    options[0].startDate.toJSDate(),
    options[0].endDate.toJSDate(),
  ]);
  useEffect(() => {
    auth.getUsers().then((res) => {
      let usersdata: any = [];
      res.map((el: any, index: number) => {
        let obj = {
          id: index + 1,
          computerId: el.computerId,
          computerName: el.computerName,
        };
        usersdata.push(obj);
        return 0;
      });
      setUsers(usersdata);
    });
    callTimeUtil(currentUser, startEndDate[0], startEndDate[1]);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  localStorage.setItem("CurrentUser", String(currentUser));

  function getUserName(id: number) {
    const currentUser = users.filter((el: any) => {
      return el.id === id;
    });
    return currentUser[0].computerName;
  }
  function getUser(id: number) {
    const currentUser: any = users.filter((el: any) => {
      return el.id === id;
    });
    return currentUser[0];
  }

  function focus(
    nextShow: boolean,
    el: MutableRefObject<HTMLInputElement | null>
  ) {
    if (nextShow) {
      setTimeout(() => el.current?.focus());
    }
  }
  const handleClearSearchState = () => {
    setDropDownSearchTerm("");
  };
  return (
    <div className="page-title justify-content-sm-center flex-sm-column d-md-flex flex-md-row justify-content-md-between align-items-center timeutilHeader">
      <div className="row g-2">
        <div className="col-auto rsuite-custom-class filterBody time-utlization-calendar">
          <div className="topFilter row g-2 mr-0">
            <DateRangePicker
              style={disabled ? { pointerEvents: "none" } : {}}
              cleanable={false}
              format="MMM dd, yyyy"
              value={startEndDate}
              ranges={dateRangePickerRanges as any}
              disabledDate={afterToday()}
              onChange={(value: any) => {
                if (value !== null) {
                  setStartEndDate(value);
                }
              }}
            />
            <i className="icon-down-open-mini" />
          </div>
        </div>
        <button
          className="time-apply-button"
          disabled={disabled}
          onClick={() => {
            if (currentUser === undefined) {
              alert("choose a userName");
            } else {
              callTimeUtil(currentUser, startEndDate[0], startEndDate[1]);
            }
          }}
        >
          Apply
        </button>
      </div>
      <div className="row g-2">
        <div className="topFilter col-sm-12 col-md-auto filterBody">
          <div className="selectpicker-iconUsers w-auto">
            <div className="dropdown bootstrap-select w-100">
              <Dropdown
                onSelect={(eventKey: any) => {
                  if (eventKey !== null) {
                    if (eventKey === "all") {
                      setCurrentUser(0);
                      setCurrentUserName("All Users");
                      setUserId(0);
                    } else {
                      setUserId(getUser(Number(eventKey)).id);
                      setCurrentUser(getUser(Number(eventKey)).computerId);
                      setCurrentUserName(getUserName(Number(eventKey)));
                    }
                  }
                }}
                onToggle={(nextShow: any) => {
                  focus(nextShow, userInputEl);
                  handleClearSearchState();
                }}
              >
                <Dropdown.Toggle
                  variant="light"
                  className="w-100"
                  style={
                    disabled
                      ? { pointerEvents: "none", width: "auto" }
                      : { width: "auto" }
                  }
                >
                  {currentUser === undefined ? "Select User" : currentUserName}
                </Dropdown.Toggle>

                <Dropdown.Menu className="user-filter-dropdown-menu">
                  <div className="dropdown-search-input table-search">
                    <input
                      ref={userInputEl}
                      type="search"
                      className="form-control form-control-sm"
                      placeholder=""
                      aria-controls="dtBasicExample"
                      value={dropdownSearchTerm}
                      onChange={(e) => setDropDownSearchTerm(e.target.value)}
                    />
                  </div>
                  <Dropdown.Item
                    eventKey={"all"}
                    onClick={() => setDropDownSearchTerm("")}
                    className={`${
                      currentUser === 0
                        ? "page-header-dropdown page-header-dropdown-light-text-active"
                        : "page-header-dropdown"
                    }`}
                  >
                    All Devices
                  </Dropdown.Item>
                  {users
                    .filter((user) => {
                      if (dropdownSearchTerm === "") {
                        return user;
                      } else if (
                        user.computerName
                          .toLowerCase()
                          .includes(dropdownSearchTerm.toLowerCase())
                      ) {
                        return user;
                      }
                      return 0;
                    })
                    .map((el: any, index: number) => {
                      return (
                        <Dropdown.Item
                          onClick={() => setDropDownSearchTerm("")}
                          href="#"
                          key={index}
                          eventKey={el.id}
                          // active={index === selectedOptionIndex}
                          className={`${
                            index + 1 === userid
                              ? "page-header-dropdown page-header-dropdown-light-text-active"
                              : "page-header-dropdown"
                          }`}
                        >
                          {el.computerName}
                        </Dropdown.Item>
                      );
                    })}
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
