import "rsuite/dist/rsuite.css";
import "./css/fontello.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-toastify/dist/ReactToastify.css";
import "./css/styles.css";
import "./css/customStyles.css";

import React from "react";
import { Routes, Route, Navigate, Outlet } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { useAuth } from "./context/AuthContext";
import UnauthenticatedApp from "./UnauthenticatedApp";
import AuthenticatedApp from "./AuthenticatedApp/AuthenticatedApp";
import ProtectedComponent from "./utils/ProtectedComponent";
import routeConfig from "./utils/routeConfig";
import defaultRoutes from "./staticData/defaultRoutes";

function App() {
  const { user } = useAuth();

  const getDefaultRoute = (permissions: string[]): string => {
    for (let permission of permissions) {
      if (defaultRoutes[permission]) return defaultRoutes[permission];
    }
    return "/unauthorized"; // fallback route if no permissions match
  };

  return (
    <div>
      <Routes>
        {user === undefined ? (
          <>
            <Route path="*" element={<Navigate to="/" />} />
            <Route path="/" element={<UnauthenticatedApp />} />
          </>
        ) : (
          <>
            <Route
              element={
                <AuthenticatedApp>
                  <Outlet />
                </AuthenticatedApp>
              }
            >
              {routeConfig.map(({ path, component, requiredPermissions }) => (
                <Route
                  key={path}
                  path={path}
                  element={
                    requiredPermissions ? (
                      <ProtectedComponent
                        component={component}
                        user={user}
                        requiredPermissions={requiredPermissions}
                      />
                    ) : (
                      React.createElement(component)
                    )
                  }
                />
              ))}
              <Route
                path="*"
                element={<Navigate to={getDefaultRoute(user.permissions)} />}
              />
            </Route>
          </>
        )}
      </Routes>
      <ToastContainer />
    </div>
  );
}

export default App;
