import React, { ReactNode, useState } from "react";
import { Chart } from "react-google-charts";
import { TimeUtil } from "../client/AuthedClient";
import { Loader } from "../components/Loader/Loader";
import { TimeUtiHeader } from "../components/TimeUtiHeader/TimeUtiHeader";
import { useAuthedClient } from "../context/AuthedClientContext";
import { DateTime } from "luxon";
import fragment from "../utils/fragment";
import { Alert } from "react-bootstrap";
import { map12To24 } from "../utils/time-util/map12to24";
import ReactApexChart from "react-apexcharts";
import * as _ from "lodash";
import "../css/ToolTip&LegendStyles.css";
export default function TimeUtilization() {
  const authedClient = useAuthedClient();

  /**
   * Added `date` to `loading` state to pass it to `loaded` state.
   */

  type State =
    | { kind: "not-all-filters-selected" }
    | { kind: "loading"; date: DateTime }
    | { kind: "loaded"; date: DateTime; filteredTimeUtilData: any }
    | any;

  type Msg =
    | { kind: "filters-updated"; id: number | undefined; date: any; date2: any }
    | { kind: "response-received"; timeUtilData: Array<TimeUtil> };

  const [state, setState] = useState<State>({
    kind: "not-all-filters-selected",
  });
  const [currentTab, setCurrentTab] = useState(0);
  function update(state: State, msg: Msg): State {
    if (
      (state.kind === "not-all-filters-selected" || state.kind === "loaded") &&
      msg.kind === "filters-updated"
    ) {
      if (msg.id === undefined) {
        return { kind: "not-all-filters-selected" };
      } else {
        callTimeUtil(msg.id, msg.date, msg.date2);

        return { kind: "loading", date: msg.date };
      }
    }

    if (state.kind === "loading" && msg.kind === "response-received") {
      return {
        kind: "loaded",
        date: state.date,
        filteredTimeUtilData: msg.timeUtilData,
      };
    }

    return state;
  }

  function sendMsg(msg: Msg): void {
    setState((state: any) => update(state, msg));
  }
  function callTimeUtil(id: number, date: DateTime, date2: DateTime) {
    const dateformatted = `${date.toJSDate().getFullYear()}-${
      date.toJSDate().getMonth() + 1
    }-${date.toJSDate().getDate()}`;
    const dateformatter2 = `${date2.toJSDate().getFullYear()}-${
      date2.toJSDate().getMonth() + 1
    }-${date2.toJSDate().getDate()}`;
    authedClient
      .getTimeUtilData2(id, dateformatted, dateformatter2)
      .then((res) =>
        sendMsg({
          kind: "response-received",
          timeUtilData: res,
        })
      );
  }
  var colorArray = [
    "#ffd101",
    "#00f991",
    "#00c2ff",
    "#8c00ff",
    "#fe00b0",
    "#E6B333",
    "#3366E6",
    "#999966",
    "#99FF99",
    "#B34D4D",
    "#80B300",
    "#809900",
    "#E6B3B3",
    "#6680B3",
    "#66991A",
    "#FF99E6",
    "#CCFF1A",
    "#FF1A66",
    "#E6331A",
    "#33FFCC",
    "#66994D",
    "#B366CC",
    "#4D8000",
    "#B33300",
    "#CC80CC",
    "#66664D",
    "#991AFF",
    "#E666FF",
    "#4DB3FF",
    "#1AB399",
    "#E666B3",
    "#33991A",
    "#CC9999",
    "#B3B31A",
    "#00E680",
    "#4D8066",
    "#809980",
    "#E6FF80",
    "#1AFF33",
    "#999933",
    "#FF3380",
    "#CCCC00",
    "#66E64D",
    "#4D80CC",
    "#9900B3",
    "#E64D66",
    "#4DB380",
    "#FF4D4D",
    "#99E6E6",
    "#6666FF",
  ];

  let currentUser = Number(localStorage.getItem("CurrentUser"));

  const view: Array<ReactNode> = [];
  const alldata: Array<string> = [];
  //get unique app names
  function getchartappsNames() {
    if (state.kind === "loaded") {
      state.filteredTimeUtilData.data.map((hour: any) => {
        if (hour.apps.length > 0) {
          hour.apps.map((app: any) => {
            alldata.push(app.processName);
            return 0;
          });
        }
        return 0;
      });
    }
    let uniquename = new Set<string>();
    for (const data of alldata) {
      uniquename.add(data);
    }
    return uniquename;
  }

  let allAppPieData: any = [];

  function renderAppName(name: string) {
    if (name.length > 40) {
      const index = name.indexOf(":");
      if (index > 0) return name.slice(0, index);
      else return name;
    } else return name;
  }

  // get app data
  function groupdatatopieData() {
    allAppPieData = [];
    let pieData: any = [];
    const names: any = getchartappsNames();
    const namesarr: any = [];
    let sum = 0;
    let others = 0;
    let irrlevantNames: any = [];
    names.forEach((element: any) => {
      namesarr.push(element);
    });
    namesarr.map((el: any) => {
      if (state.kind === "loaded") {
        state.filteredTimeUtilData.data.map((apps: any) => {
          if (apps.apps.length > 0) {
            apps.apps.map((app: any) => {
              if (app.processName === el) {
                sum += app.duration;
              }
              return 0;
            });
          }
          return 0;
        });
      }
      return 0;
    });
    namesarr.map((el: any, number: number) => {
      let duration = 0;
      if (state.kind === "loaded") {
        state.filteredTimeUtilData.data.map((apps: any) => {
          if (apps.apps.length > 0) {
            apps.apps.map((app: any) => {
              if (app.processName === el) {
                duration += app.duration;
              }
              return 0;
            });
          }
          return 0;
        });
        allAppPieData.push({ name: el, percentage: (duration / sum) * 100 });
        if ((duration / sum) * 100 > 10) {
          pieData.push(duration);
        } else {
          others += duration;
          irrlevantNames.push(number);
        }
      }
      return 0;
    });
    for (let i = 0; i < irrlevantNames.length; i++) {
      delete namesarr[irrlevantNames[i]];
    }
    pieData.push(others);
    namesarr.push("others");
    return [pieData, namesarr, sum];
  }

  //stracture stacked bar array
  function groupdatatochartdata() {
    const chartData = [];
    const names: any = getchartappsNames();
    const namesarr = [];
    namesarr.push("names");
    names.forEach((element: any) => {
      const obj = {
        type: "number",
        label: element,
      };
      namesarr.push(obj);
    });
    chartData.push(namesarr);
    if (state.kind === "loaded") {
      for (let i = 0; i < state.filteredTimeUtilData.data.length; i++) {
        let hourarray = [];
        let sumData = 0;
        const dataofhour = state.filteredTimeUtilData.data[i].apps;
        dataofhour.map((el: any) => {
          sumData += el.duration;
          return 0;
        });
        hourarray.push(
          state.filteredTimeUtilData.timeLabel !== "Hour"
            ? state.filteredTimeUtilData.data[i].xAxisValue
            : map12To24(String(state.filteredTimeUtilData.data[i].xAxisValue))
        );
        names.forEach((value: any) => {
          let processdata = dataofhour.filter((app: any) => {
            return app.processName === value;
          });
          if (processdata.length > 0) {
            if (currentUser === 0) {
              hourarray.push(
                (Number(processdata[0].duration / sumData) * 100).toFixed(2)
              );
            } else {
              hourarray.push((processdata[0].duration / 60).toFixed(2));
            }
          } else {
            hourarray.push(undefined);
          }
        });
        chartData.push(hourarray);
      }
    }
    return chartData;
  }
  let namesarr: any = [];

  let tempnamnes = groupdatatopieData()[1];
  for (var i = 0; i < tempnamnes.length; i++) {
    if (tempnamnes[i] !== undefined) {
      namesarr.push(tempnamnes[i]);
    }
  }
  console.log("====================================");
  console.log(namesarr);
  console.log("====================================");
  let piedata = groupdatatopieData()[0];
  let datachart: any = groupdatatochartdata();
  let sum = groupdatatopieData()[2];

  if (state.kind === "loading") {
    datachart = groupdatatochartdata();
    piedata = groupdatatopieData()[0];

    view.push(<Loader />);
  }
  if (state.kind === "not-all-filters-selected") {
    view.push(
      <div className="white-box">
        <Alert className="m-0">
          <div className="alert-icon">
            <i className="icon-bell" />
          </div>
          <div className="alert-message">
            <strong>Please</strong> choose the username and date to display the
            data here!
          </div>
        </Alert>
      </div>
    );
  }

  if (
    state.kind === "loaded" &&
    datachart.length > 0 &&
    datachart[0].length <= 1
  ) {
    view.push(
      <div className="white-box">
        <Alert className="m-0">
          <div className="alert-icon">
            <i className="icon-bell" />
          </div>
          <div className="alert-message">There is no data for this day!</div>
        </Alert>
      </div>
    );
  }
  const ytitle =
    currentUser === 0 ? "Applications Percentage (%)" : "Duration (minutes)";
  if (
    state.kind === "loaded" &&
    datachart.length > 0 &&
    datachart[0].length > 1
  ) {
    view.push(
      <div className="customTab">
        <ul className="nav nav-tabs">
          <li
            className="nav-item"
            role="presentation"
            onClick={() => setCurrentTab(0)}
          >
            <button
              type="button"
              id="uncontrolled-tab-example-tab-1"
              role="tab"
              data-rr-ui-event-key="1"
              className={currentTab === 0 ? "nav-link active" : "nav-link"}
            >
              Application Usage
            </button>
          </li>
          <li
            className="nav-item"
            role="presentation"
            onClick={() => setCurrentTab(1)}
          >
            <button
              type="button"
              id="uncontrolled-tab-example-tab-1"
              role="tab"
              data-rr-ui-event-key="1"
              className={currentTab === 1 ? "nav-link active" : "nav-link"}
            >
              Applications Summary
            </button>
          </li>
        </ul>
        <div className="white-box">
          {currentTab === 0 ? (
            <div
              className="chart_TimeUtilization"
              style={{ width: "65vw", margin: "0 auto", overflow: "initial" }}
            >
              <h3>{currentUser === 0 ? 'Applications Usage Percentage' : "Applications Usage Duration"}</h3>
              <div className="timeutilWrapper">
                <Chart
                  chartType="ColumnChart"
                  height="400px"
                  options={{
                    legend: { position: "top" },
                    chartArea: { width: "90%", height: "60%" },
                    isStacked: true,
                    bar: { groupWidth: 30 },
                    hAxis: {
                      slantedText:
                        state.filteredTimeUtilData.timeLabel === "Hour"
                          ? false
                          : true,
                      textStyle: {
                        fontSize: 12,
                      },
                      title: `Time in ${state.filteredTimeUtilData.timeLabel}s`,
                      minValue: 0,
                      format: "h%",
                      viewWindow: {
                        min: [7, 30, 0],
                        max: [31, 30, 0],
                      },
                    },
                    vAxis: {
                      title: ytitle,
                      ticks: currentUser === 0 ? [0, 50, 100] : undefined,
                    },
                  }}
                  data={datachart}
                  width="100%"
                />
              </div>
            </div>
          ) : (
            <>
              <h3 style={{ textAlign: "center", color: "#4650be" }}>
                Application Usage Percentage{" "}
              </h3>
              <ReactApexChart
                height={"350px"}
                series={piedata}
                type="pie"
                options={{
                  colors: colorArray,
                  legend: {
                    position: "top",
                  },
                  labels: namesarr,
                  responsive: [
                    {
                      options: {
                        chart: {
                          width: "100%",
                        },
                        legend: {
                          show: false,
                        },
                      },
                    },
                  ],
                  tooltip: {
                    custom: function ({ series, seriesIndex }) {
                      if (namesarr[seriesIndex] === "others") {
                        const HTML: any = `<div class="custom-tooltip">
                        
                        <div class="custom-tooltip-main-child">
                        <ul class="tooltip-class-ul">${allAppPieData
                          .filter(
                            (app: any) =>
                              namesarr.indexOf(app.name) === -1 &&
                              app.percentage.toFixed(1) > 0.09
                          )
                          .map(
                            (app: any, index: number) =>
                              `<li class="tooltip-class-li" key={${index}}>•  ${renderAppName(
                                app.name
                              )} ${app.percentage.toFixed(1)}%</li>`
                          )}</ul>
                         
                        </div> 
                        <div>`;
                        return HTML.replaceAll(",", "");
                      } else {
                        return `<div className="custom-tooltip">
                        <div class="custom-tooltip-main-child">
                        ${renderAppName(namesarr[seriesIndex])} ${(
                          (Number(series[seriesIndex]) / Number(sum)) *
                          100
                        ).toFixed(1)}%
                        </div>
                        </div>`;
                      }
                    },
                  },
                }}
              />
            </>
          )}
        </div>
      </div>
    );

    // Copy-pasted from TimeUtilization
    //accordion data
    let accordionData: any = [];
    state.filteredTimeUtilData.data.map((el: any) => {
      const apps = el.apps;
      const appsInHour: any = [];
      let sum = 0;
      if (apps.length > 0) {
        apps.map((app: any) => {
          const appObj = {
            ApplicationName: app.processName,
            minutes: app.duration / 60,
          };
          sum += app.duration;
          appsInHour.push(appObj);
          return 0;
        });
        const hourObj = {
          hour:
            state.filteredTimeUtilData.timeLabel === "Hour"
              ? map12To24(String(apps[0].xAxisValue))
              : apps[0].xAxisValue,
          show: false,
          AppData: appsInHour,
          sumData: (sum / 60).toFixed(2),
        };
        accordionData.push(hourObj);
      }
      return 0;
    });

    view.push(
      <div className="white-box">
        <div className="accordion" id="accordionExample">
          {accordionData.map((el: any, index: number) => {
            let timeHours = Math.floor(el.sumData / 60);
            let minutes = (el.sumData - timeHours * 60).toFixed(2);
            const sortedAppData = _.orderBy(el.AppData, "minutes", "desc");
            return (
              <React.Fragment key={index}>
                <div
                  className="accordion-item"
                  onClick={(e: any) => {
                    const element = e.target;
                    element.classList.toggle("collapsed");
                    element.parentElement.nextSibling.classList.toggle("show");
                  }}
                >
                  <h2 className="accordion-header">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseOne"
                      aria-expanded="true"
                    >
                      <i className="icon-stopwatch-1 me-2" /> {el.hour}
                      <small
                        onClick={(e: any) => {
                          const element = e.target;
                          element.parentElement.classList.toggle("collapsed");
                          element.parentElement.parentElement.nextSibling.classList.toggle(
                            "show"
                          );
                        }}
                        className="ms-2"
                      >
                        {currentUser === 0 ? (
                          <></>
                        ) : state.filteredTimeUtilData.timeLabel === "Day" ? (
                          `${timeHours} Hours and ${minutes} minutes`
                        ) : el.sumData === 0 ? (
                          "(Less Than 1 Minute)"
                        ) : (
                          `(${el.sumData} Minutes)`
                        )}
                      </small>
                    </button>
                  </h2>
                  <div
                    id="collapseOne"
                    className={"accordion-collapse collapse "}
                    data-bs-parent="#accordionExample"
                  >
                    <div className="accordion-body">
                      <div className="timeUtilization-info">
                        {sortedAppData.map((card: any, index: number) => {
                          let timeHours = Math.floor(card.minutes / 60);
                          let minutes = (card.minutes - timeHours * 60).toFixed(
                            2
                          );
                          return (
                            <div className="card" key={index}>
                              <div className="card-body">
                                <div className="row">
                                  <div className="col-xl-4 col-lg-5 col-md-6">
                                    {card.ApplicationName}
                                  </div>
                                  <div className="col-xl-8 col-lg-7 col-md-6">
                                    {currentUser === 0
                                      ? `${(
                                          (card.minutes / el.sumData) *
                                          100
                                        ).toFixed(2)}%`
                                      : state.filteredTimeUtilData.timeLabel ===
                                        "Day"
                                      ? `${timeHours} Hours and ${minutes} minutes`
                                      : Math.floor(card.minutes) === 0
                                      ? "Less Than 1 Minute"
                                      : `${card.minutes.toFixed(2)} Minutes`}
                                    <span></span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                </div>
              </React.Fragment>
            );
          })}
        </div>
      </div>
    );
  }

  return (
    <div className="page-container">
      <TimeUtiHeader
        disabled={state.kind === "loading"}
        callTimeUtil={(id, date, date2) => {
          sendMsg({
            kind: "filters-updated",
            id,
            date: DateTime.fromJSDate(date),
            date2: DateTime.fromJSDate(date2),
          });
        }}
      />
      {fragment(view)}
    </div>
  );
}
